.EZDrawer .EZDrawer__checkbox {
  display: none;
}

.EZDrawer .EZDrawer__checkbox:checked ~ .EZDrawer__overlay {
  display: block;
  opacity: 1;
}

.EZDrawer .EZDrawer__checkbox:checked ~ .EZDrawer__container {
  visibility: visible;
  transform: translate3d(0, 0, 0) !important;
}

.EZDrawer .EZDrawer__overlay {
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.3s ease;
}

.EZDrawer .EZDrawer__container {
  position: fixed;
  visibility: hidden;
  transition: all;
  overflow-y: auto;
  flex-direction: column;
  width: 100% !important;
  max-width: 100%;
  transition: all 0.3s ease;
  box-shadow: none !important;
  background-color: #fff !important;
  display: flex;
  align-items: start;
}

@media (min-width: 1024px) {
  .EZDrawer .EZDrawer__container {
    flex-direction: row;
    max-width: 96%;
    background-color: transparent !important;
  }
}
@media (min-width: 1440px) {
  .EZDrawer .EZDrawer__container {
    max-width: 90%;
  }
}
